import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ('../views/Login.vue')
    },
    {
        path: '/selyylist',
        name: 'SelYyList',
        component: () =>
            import ('../views/SelYyList.vue')
    },
    {
        path: '/loginout',
        name: 'LoginOut',
        component: () =>
            import ('../views/LoginOut.vue')
    },
    {
        path: '/addyy',
        name: 'AddYy',
        component: () =>
            import ('../views/AddYy.vue')
    },
    {
        path: '/selyysj',
        name: 'SelYySj',
        component: () =>
            import ('../views/SelYySj.vue')
    },
    {
        path: '/showyy',
        name: 'ShowYy',
        component: () =>
            import ('../views/ShowYy.vue')
    },
    {
        path: '/showyyinfo',
        name: 'ShowYyInfo',
        component: () =>
            import ('../views/ShowYyInfo.vue')
    },
    {
        path: '/scanqr',
        name: 'ScanQr',
        component: () =>
            import ('../views/ScanQr.vue')
    },
    {
        path: "*", 
        redirect: "/"
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router