import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        sysTitle: '太仓市体育馆场馆预约系统',
        photoPath: require('../assets/photo.jpg'),
        logoPath: require('../assets/logo.png'),
        ymqImgPath: require('../assets/ymq.png'),
        ppqImgPath: require('../assets/ppq.png'),
        zqImgPath: require('../assets/zq.png'),
        lqImgPath: require('../assets/lq.png'),
        qpqImgPath: require('../assets/qpq.png'),
        jsfImgPath: require('../assets/jsf.png'),
        tip1: require('../assets/tip1.png'),
        userInfo: {
            userName: '',
            userCardID: '',
            loginType: 0,
            token: '',
            wechatUserCode: ''
        },
        weChatAppId: 'wxa2c4433c4192e631', // 请求需要的传入的appid
        weChatUrl: 'https://tctyg.wx.or-home.com/' //回调的地址,当前项目地址
    },
    mutations: {
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
        },
    }
})