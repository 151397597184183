<template>
  <div id="app">
    <Scroll>
      <router-view />
    </Scroll>
  </div>
</template>

<script>
import Scroll from "@/components/Scroll.vue";
export default {
  name: "App",
  components: {
    Scroll
  },
  created() {
    window.sessionStorage.setItem('firstEntryUrl', window.location.href)
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
    
    document.body.addEventListener('touchmove', function (e) {
      e.preventDefault();
    }, {
      passive: false
    });
  }
};
</script>